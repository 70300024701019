import React from 'react';
import styled from 'styled-components';
import videoSrc from '../../assets/output.mp4'; // Assuming this is the path to your video file
import { TrustedBy } from '../trustedBy';

const Container = styled.div`
  max-width: 1200px;
  flex-direction: column;
  align-items: center;
  margin: 0 auto; // Centers the container
  padding: 140px 15px 0; // Top padding 140px, horizontal padding 15px, no bottom padding
  display: flex;
  
  @media (max-width: 768px) {
    padding-top: 80px; // Adjusts top padding on smaller screens
  }
`;

const StyledVideo = styled.video`
  vertical-align: middle;
  display: block; // Changed from inline-block to block for better control
  width: 100%; // Ensures video stretches to the container's width
  align-self: center; // Centers video in the flex container
  max-height: 500px; // Optional: Limits video height for large screens
`;

export const MidSection = () => {
  return (
    <Container>
      <StyledVideo src={videoSrc} controls />
      <TrustedBy />
    </Container>
  );
};
