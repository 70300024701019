import React, { useState } from 'react';
import styled from 'styled-components';
import { Collapse, Card, CardBody, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { constants } from '../../data/common';

// Adjusted for responsiveness
const FAQWrapper = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  min-height:600px;
  padding: 20px; // Reduced padding for smaller screens
`;

// Styled Button with responsive considerations
const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 5px 0;
  background-color: ${constants.primaryColor};
  color: #333;
  border: none;
  &:hover {
    background-color: ${constants.secondaryColor};
  }
`;

// Styled Card with responsive considerations
const StyledCard = styled(Card)`
  width: 100%;
  border: none;
  border-bottom: 1px solid #333; // Ensures visibility on all backgrounds
  box-shadow: none;
  margin:10px;
`;

// Header with responsive font size
const StyledHeader = styled.h2`
  margin-top: 0;
  margin-bottom: 20px; // Increased spacing for better readability
  font-size: 24px; // Reduced font size for mobile devices
  font-weight: 500;
  line-height: 1.2em;
  text-align: center; // Centered text for better aesthetics on narrow screens

  @media (min-width: 768px) {
    font-size: 50px; // Larger font size for larger screens
  }
`;

export const FAQSection = () => {
  const [open, setOpen] = useState(null);

  const toggle = (id) => {
    setOpen(open === id ? null : id);
  };

  const faqs = [
    { 
      id: 1, 
      question: 'What are the costs associated with establishing and operating a call center team through Eastgate compared to in-house options?', 
      answer: 'We offer cost-effective outsourcing solutions compared to in-house options, with average cost savings of over 40%. You can benefit from reduced overhead costs, lower labor expenses, and increased operational efficiency by outsourcing your call center operations to us.' 
    },
    { 
      id: 2, 
      question: 'Could you provide additional details about the recruitment process for assembling my call center team?', 
      answer: 'Of course, our recruitment process involves sourcing candidates, screening resumes, conducting interviews, and assessing language proficiency and job-related skills. We work closely with you to understand your specific requirements and ensure that only the most suitable candidates are selected for your call center team.' 
    },
    { 
      id: 3, 
      question: 'How do you manage communication and collaboration across time zones between my company and the call center team?', 
      answer: 'We enable seamless communication and collaboration across various channels, including email, phone calls, video conferencing, and project management tools. Our locations operate in sync with US time zones, ensuring efficient coordination and maximizing productivity.' 
    },
    { 
      id: 4, 
      question: 'How do you handle fluctuations in call volume and staffing requirements?', 
      answer: 'Our operational model is designed to flexibly adjust to your changing call volume and staffing needs. We utilize a strategic approach to workforce management that includes scalable staffing solutions, rigorous training programs, and advanced forecasting tools to ensure we can quickly adapt to your business demands, maintaining high levels of customer service at all times.' 
    }
    // Add more FAQs as needed
  ];
  return (
    <FAQWrapper>
      <StyledHeader>Frequently Asked Questions</StyledHeader>
      {faqs.map(({ id, question, answer }) => (
        <StyledCard key={id}>
          <StyledButton onClick={() => toggle(id)}>
            {question}
            <FontAwesomeIcon icon={open === id ? faMinus : faPlus} />
          </StyledButton>
          <Collapse isOpen={open === id}>
            <CardBody>
              {answer}
            </CardBody>
          </Collapse>
        </StyledCard>
      ))}
    </FAQWrapper>
  );
};