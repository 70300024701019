import React, { useState,useRef,useEffect } from 'react';
import styled from 'styled-components';
import { constants } from '../../data/common';
import { Button } from 'reactstrap';
import { BreakingPoint } from '../breakingPoint';
import { Link } from 'react-scroll'; 

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5rem 5rem;
  background-color: transparent;
  margin: 2em;
  border-bottom: 1px solid rgba(0, 60, 214, .1);

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    margin: 0em;
    justify-content: start;
    position: fixed;
    width: 70%;
    max-width: 300px;
    height: 100vh;
    top: 0;
    left: 0;
    transform: ${({ isOpen }) => isOpen ? 'translateX(0)' : 'translateX(-100%)'};
    transition: transform 0.3s ease-in-out;
    background-color: white;
    z-index: 40;
    border-right: 1px solid rgba(0, 60, 214, .1);
  }
`;

const ToggleButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 30px; 
  cursor: pointer;
  margin:2rem;
`;

const MobBar = styled.div`
  display: flex;
  justify-content: flex-end; /* Align toggle button to the right */
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 30; /* Ensure it's above other content */
  background-color: transparent; /* Match the Nav background */
`;

const Logo = styled.a`
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
  color: currentColor;
`;

const NavMenu = styled.ul`
  display: flex;
  list-style: none;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const MenuItem = styled(Link)`
  color: ${constants.secondaryColor};
  font-family: 'Inter Tight', sans-serif;
  font-size: 16px;
  line-height: 1.1em;
  cursor: pointer;
  margin: 10px;
  text-decoration: none;

  &:hover {
    color: ${constants.tertaryColor};
  }
`;

const RequestButton = styled.button`
  background-color: transparent;
  color: ${constants.tertaryColor};
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Inter Tight', sans-serif;
  font-size: 18px;
  line-height: 1.1em;
`;

const CloseIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 30px;
  padding: 1rem;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 50; // Make sure it's above other content inside Nav
`;

export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = BreakingPoint(); // Assuming BreakingPoint returns an object with isMobile boolean
  const navRef = useRef();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      {isMobile && (
        <MobBar>
          <ToggleButton onClick={toggleMenu}>{isOpen ? '' : <span>&#9776;</span>}</ToggleButton>
        </MobBar>
      )}
      <Nav isOpen={isOpen} ref={navRef}>
        {isOpen && (
          <CloseIcon onClick={toggleMenu}>×</CloseIcon>
        )}
        <Logo href="#">EASTGATE</Logo>
        <NavMenu>
          {[{name: "Home", to: "topSection"}, {name: "Services", to: "Services"}, {name: "About Us", to: "aboutUs"}].map((item) => (
            <li key={item.name}>
              {/* Use Link component for smooth scroll */}
              <MenuItem
                to={item.to}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={() => isMobile && setIsOpen(false)} // Close menu on item click in mobile view
              >
                {item.name}
              </MenuItem>
            </li>
          ))}
        </NavMenu>
        <RequestButton></RequestButton>
      </Nav>
    </>
  );
};