import React from 'react';
import styled from 'styled-components';
import { Button } from 'reactstrap'; // Ensure reactstrap is installed
import { constants } from '../../data/common';
import { Link } from 'react-scroll';

const Container = styled.div`
  max-width: 1200px;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 5em 15px 0;
  display: flex;
`;

const StyledHeader = styled.h1`
  background-image: radial-gradient(circle farthest-corner at 0% 40%, #000, #003cd6);
  color: transparent; // Adjusted for better readability
  text-align: center;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  margin: 0;
  padding:10px;
  font-size: 6rem; // Adjusted for scalability
  font-weight: 500;
  line-height: 1.1em;
  @media (max-width: 768px) {
    font-size: 3.4rem; // Smaller font size on smaller screens
  }
`;



const StyledP = styled.p`
  width: 70%;
  text-align: center;
  font-size: 20px;
  color: #687076;
  margin-bottom: 2em;
  margin-top: 2em;
  font-weight: 400;
  line-height: 1.6em;
  @media (max-width: 768px) {
    font-size: 18px; // Slightly smaller font size on smaller screens
    width: 90%; // More width for better readability
    br {
      display: none; // Hide <br/> tags on smaller screens
    }
  }
`;

const SmallText = styled.div`
  background-color: #ecedee;
  color: ${constants.tertaryColor};
  border-radius: 8px;
  padding: 4px 10px;
  font-family: 'Inter Tight', sans-serif;
  font-size: 20px;
  display: inline-block;
  @media (max-width: 768px) {
    font-size: 18px; // Adjust font size for smaller screens
  }
`;

const StyledButton = styled(Button)`
  color: ${constants.primaryColor};
  background-color: ${constants.tertaryColor};
  border: 1px solid ${constants.tertaryColor};
  text-align: center;
  border-radius: 8px;
  padding: 8px 14px;
  font-size: 16px;
  font-weight: 600;
  transition: all .3s;
  &:hover {
    background-color: ${constants.tertaryColor}; // Example hover effect
    color: ${constants.primaryColor};
  }
  @media (max-width: 768px) {
    font-size: 15px; // Slightly smaller font size for smaller screens
  }
`;

export const TopSection = () => {
  return (
    <Container>
      <SmallText>Hello! We are</SmallText>
      <StyledHeader>Eastgate</StyledHeader>
      <StyledP>
      Experience personalized customer support solutions designed just for you. Our dedicated team is here to provide prompt assistance, ensuring happy customers and lasting loyalty. Let's work together to elevate your brand's support experience
      </StyledP>
      <StyledButton>
  <Link to="ContactUs" spy={true} smooth={true} offset={-70} duration={500}>
    Contact Us
  </Link>
</StyledButton>;
    </Container>
  );
};