import {useState,useEffect}from 'react';



 export const BreakingPoint = () => {
    const [isMobile, setMobile] = useState(window.innerWidth < 768);
  
    const updateMedia = () => {
      setMobile(window.innerWidth < 768);
    };
  
    useEffect(() => {
      window.addEventListener("resize", updateMedia);
      return () => window.removeEventListener("resize", updateMedia);
    });
  
    return {isMobile};
  };