import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact, faAngular, faVuejs } from '@fortawesome/free-brands-svg-icons';
import { constants } from '../../data/common';

const continuousSlide = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
`;


const Wrapper = styled.div`
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  overflow: hidden;
  white-space: nowrap; // Prevent wrapping to a new line
`;


const AnimatedLogo = styled.div`
  display: inline-flex; // Inline-flex to keep logos in a row
  animation: ${continuousSlide} 20s linear infinite;
  padding: 0 20px; // Add padding to space out the logos evenly

  &:not(:first-child) {
    margin-left: 50px; // Space between logos
  }
`;

const Label=styled.p`

font-family: 'Inter Tight', sans-serif;
font-size: 20px;
color:${constants.secondaryColor};


`;

export const TrustedBy = () => {
    // Render multiple instances for a continuous effect
    const logos = [ ];
  
    return (
      <Wrapper>
        <Label></Label>
        <>
        {Array.from({ length: 4}).flatMap(() => // Create enough logos to fill and cycle through the view
          logos.map((logo, index) => (
            <AnimatedLogo key={index}>
              {logo}
            </AnimatedLogo>
          ))
        )}
        </>
      </Wrapper>
    );
  };
  
  
  