import React from 'react';
import styled from 'styled-components';
import { Projs } from '../projectCards';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import { faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { faHeadset } from '@fortawesome/free-solid-svg-icons';
import { CardGroup } from 'reactstrap';

const Container = styled.div`
  max-width: 1200px;
  flex-direction: column;
  align-items: flex-start; // Align items to the left
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 140px;
  display: flex;
  @media (max-width: 768px) {
    padding: 80px 15px 0px; // Adjusted for clarity
  }
`;

const ShowCaseContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 15px;
  padding-bottom:40px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  margin: 0px 120px;
  padding: 0px 15px;
  
  @media (max-width: 992px) {
    margin: 0px 60px; // Reduced margin for tablets
  }

  @media (max-width: 768px) {
    margin: 0px 30px; // Further reduce for small devices
    padding: 0px 10px;
  }

  @media (max-width: 576px) {
    margin: 0px 15px; // Minimal margin for extra small devices
    padding: 0px 5px;
    flex-wrap: nowrap; // Prevent wrapping
    overflow-x: auto; // Allow horizontal scrolling if content overflows
  }
`;


 const StyledCardGroup = styled(CardGroup)`
 margin:15px;
 padding:0px 15px;`;

 const StyledHeader = styled.h2`
 margin-top: 0;
 margin-bottom: 0;
 font-size: 50px; // Default font size for larger screens
 font-weight: 500;
 line-height: 1.1em;
 text-wrap:none;

 // Adjust font size for medium screens (tablets)
 @media (max-width: 992px) {
   font-size: 40px;
 }

 // Adjust font size for small screens (large phones)
 @media (max-width: 768px) {
   font-size: 30px;
 }

 // Adjust font size for extra small screens (small phones)
 @media (max-width: 576px) {
   font-size: 24px;
 }
`;
export const ShowCase = () => {
  return (
    <ShowCaseContainer>
      <HeaderWrapper>
        <StyledHeader>What Sets Eastgate Apart?</StyledHeader>
      </HeaderWrapper>
      <StyledCardGroup>
      <Projs 
  textOnLeft={false} 
  header={"Cost-Effective Solutions"} 
  text={"By leveraging competitive labor costs, you can achieve significant savings compared to operating solely within your home country."} 
  icon={faDollarSign}
/>
<Projs 
  textOnLeft={true} 
  header={"Advanced Technological Infrastructure"} 
  text={"We provide the necessary infrastructure and facilities, including working space and technological resources, to support a wide variety of Call Center operations."} 
  icon={faNetworkWired}
/>
<Projs 
  textOnLeft={true} 
  header={"Get the Right Talent"} 
  text={"Your Call Center team is recruited by us to your exact specifications"} 
  icon={faUserCheck}
/>
      </StyledCardGroup>
    
      <Projs 
  textOnLeft={false} 
  header={"Exceptional Customer Support Services"} 
  text={"Our BPO services extend beyond traditional support; we provide comprehensive customer service solutions, including 24/7 support, multi-channel communication, and customer satisfaction strategies to enhance your business operations."} 
  icon={faHeadset}
/>
      {/*<Projs textOnLeft={true} />*/}


    </ShowCaseContainer>
  );
};