import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Container, Row, Col } from 'reactstrap';

const fadeInUpAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledContainer = styled(Container)`
  padding: 100px 20px;
  background-color: #123456; // Adjust based on your color scheme
  color: #fff;
  text-align: center;
  font-family: 'Roboto', sans-serif;
`;

const CustomCard = styled.div`
  background-color: rgba(32, 44, 51, 0.9);
  color: #fff;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 20px; // Adjusted margin for spacing
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 320px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: ${fadeInUpAnimation} 1s ease-out forwards; // Increased duration and added 'forwards'
  &:hover {
    transform: translateY(-5px); // Optional: subtle hover effect
  }
  
  @media (min-width: 768px) {
    margin: 10px; // Provides spacing between cards on larger screens
  }
`;

const Title = styled.h2`
  font-size: 28px;
  margin-bottom: 20px;
  color: #ECEDEE;
  font-weight: 700;
`;

const Text = styled.p`
  font-size: 18px;
  line-height: 1.5;
  margin: 20px 0;
  font-weight: 400;
  
  @media (min-width: 768px) {
    font-size: 20px;
  }
`;
export const AboutUs = () => {
  return (
    <StyledContainer fluid>
      <Title>About Eastgate</Title>
      <Text>
        At Eastgate, we redefine the essence of Call Center BPO services, setting new standards in service excellence and operational efficiency.
      </Text>
      <Row>
        <Col xs="12" md="4">
          <CustomCard>
            <Title>Global Reach</Title>
            <Text>Empowering businesses across countries with 3 languages with exceptional customer support.</Text>
          </CustomCard>
        </Col>
        <Col xs="12" md="4">
          <CustomCard>
            <Title>Experienced Agents</Title>
            <Text>A robust team of professionals, dedicated to your success.</Text>
          </CustomCard>
        </Col>
        <Col xs="12" md="4">
          <CustomCard>
            <Title>24/7 Support</Title>
            <Text>Ensuring round-the-clock availability for seamless customer service.</Text>
          </CustomCard>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="4">
          <CustomCard>
            <Title>Client Satisfaction</Title>
            <Text>Achieving 95% positive feedback through our commitment to excellence.</Text>
          </CustomCard>
        </Col>
        <Col xs="12" md="4">
          <CustomCard>
            <Title>Innovative Solutions</Title>
            <Text>Delivering over custom projects tailored to unique business needs.</Text>
          </CustomCard>
        </Col>
        <Col xs="12" md="4">
          <CustomCard>
            <Title>Efficiency Boost</Title>
            <Text>Driving up to 75% cost reduction while enhancing service quality.</Text>
          </CustomCard>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="4">
          <CustomCard>
            <Title>Technology-Driven</Title>
            <Text>Leveraging state-of-the-art infrastructure for unparalleled service delivery.</Text>
          </CustomCard>
        </Col>
        <Col xs="12" md="4">
          <CustomCard>
            <Title>Cultural Compatibility</Title>
            <Text> We’re the voice of your brand to the world.</Text>
          </CustomCard>
        </Col>
        <Col xs="12" md="4">
          <CustomCard>
            <Title>Partnership Approach</Title>
            <Text>Building lasting relationships based on trust, respect, and mutual growth.</Text>
          </CustomCard>
        </Col>
      </Row>
    </StyledContainer>
  );
};