import React,{useState} from 'react';
import styled from 'styled-components';
import { constants } from '../data/common';
import { Navbar } from '../components/nav';
import { TopSection } from '../components/topSection';
import { Animator } from '../components/animator';
import { Button } from 'reactstrap';
import { MidSection } from '../components/midSection';
import { ShowCase } from '../components/showcase';
import { AboutUs } from '../components/blackSection';
import { FAQSection } from '../components/faq';
import { Footer } from '../footer';





const Container = styled.div`
  display: flex;
  flex-direction: column;
  width:100%;
  min-height: 100vh;
  background-color:${constants.primaryColor};
`;




export const HomePage = () => {
  return (
    <Container>
      <Navbar/>   
      <Animator id="topSection">
        <TopSection/>
      </Animator>
      <Animator direction={"left"} id="midSection"><MidSection/></Animator>
      <div id="Services"><ShowCase/></div>
      <div id="aboutUs"><AboutUs/></div>
      <div id="faqSection"><FAQSection/></div>
      <Footer/>
    </Container>
  );
};