import React from 'react';
import styled, { keyframes } from 'styled-components';

// Define keyframes for each direction
const slideInFromTop = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideInFromBottom = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideInFromLeft = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideInFromRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

// Function to choose the correct animation based on direction
const getAnimation = (direction) => {
  switch (direction) {
    case 'top':
      return slideInFromTop;
    case 'bottom':
      return slideInFromBottom;
    case 'left':
      return slideInFromLeft;
    case 'right':
      return slideInFromRight;
    default:
      return appearAnimation;
  }
};


// Define the animation keyframes
const appearAnimation = keyframes`
  from {
    transform: translate3d(-100px, -100px, 0px) scale3d(0.5, 0.5, 1) rotateX(10deg) rotateY(10deg) rotateZ(30deg) skew(10deg, 10deg);
    opacity: 0;
  }
  to {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    opacity: 1;
  }
`;

// Animator component
const AnimatedDiv = styled.div`
  animation:${({ direction }) =>  getAnimation(direction)} 1s ease-out forwards;
  transform-style: preserve-3d;
`;

export const Animator = ({ direction, children }) => {
  return <AnimatedDiv direction={direction}>{children}</AnimatedDiv>;
};
