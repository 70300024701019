import React,{useState} from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import {constants} from '../data/common';
import axios from 'axios';
import {Spinner} from 'reactstrap';


const StyledFooter = styled.footer`
  background-color: ${constants.secondaryColor};
  color: #fff;
  padding: 3rem 2rem;
  position: relative;
  display: flex;
  justify-content: space-between; /* Adjust layout to space between */
  flex-wrap: wrap; /* Ensure responsiveness */
  align-items: start; /* Align items at the start */
`;

const FooterSection = styled.div`
  flex: 1; /* Flex grow to fill available space */
  min-width: 280px; /* Minimum width before wrapping */
  padding: 0 20px; /* Padding for spacing */
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const FormInput = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: ${constants.tertaryColor};
  color: white;
  cursor: pointer;
  &:hover {
    background-color: black;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin: 0;
`;

const StyledBottomSection = styled.div`
  text-align: center;
  width: 100%;
  margin-top: 1.5rem;
  border-top: 1px solid #444;
  padding-top: 1.5rem;
`;

function ContactSection() {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await axios.post('https://bntnemttool.online:7443/api/Contact', data);
      console.log(response.data);
      setSubmitted(true); // Handle the success state
    } catch (error) {
      console.error(error);
      // Handle the error state if needed
    }
    setLoading(false);
  };

  if (submitted) {
    return <div>Thank you for contacting us!</div>;
  }

  return (
    <ContactForm onSubmit={handleSubmit(onSubmit)}>
      <h2>Contact Us</h2>
      <FormInput 
        placeholder="Name" 
        {...register("name", { required: true, minLength: 2 })} 
      />
      {errors.name && <ErrorMessage>Name is required and should be at least 2 characters.</ErrorMessage>}

      <FormInput 
        placeholder="Email" 
        type="email"
        {...register("email", { required: true, pattern: /^\S+@\S+$/i })} 
      />
      {errors.email && <ErrorMessage>A valid email is required.</ErrorMessage>}

      <FormInput 
        placeholder="Phone (e.g., 123-456-7890)" 
        {...register("phone", { 
          required: true, 
          pattern: /^(\d{3}-\d{3}-\d{4})$/ 
        })} 
      />
      {errors.phone && <ErrorMessage>Phone number is required and should match the US format (123-456-7890).</ErrorMessage>}

      <SubmitButton type="submit" disabled={loading}>
        {loading ? <Spinner/> : 'Submit'}
      </SubmitButton>
    </ContactForm>
  );
}


export function Footer() {
  return (
    <StyledFooter>
      <FooterSection>
        <h2>Office Hours</h2>
        <p>Monday - Friday: 9:00 AM - 5:00 PM</p>
        <p>Saturday: 10:00 AM - 3:00 PM</p>
        <p>Sunday: Closed</p>
      </FooterSection>
      <FooterSection>
        <h2>Email us At</h2>
        <p>info@eastgatebpo.online</p>
        <h2> Or Give Us A Call</h2>
        <p>(309)-350-9799</p>
      </FooterSection>

      <FooterSection>
      <div id="ContactUs">
        <ContactSection />
        </div>
      </FooterSection>

      <StyledBottomSection>
        <p>© 2024 EastGate. All Rights Reserved.</p>
        <p>Powered By Nihilo</p>
      </StyledBottomSection>
    </StyledFooter>
  );
}