// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter Tight', sans-serif;
  color: #11181c;
  font-family: Inter Tight, sans-serif;
  font-size: 18px;
  line-height: 1.1em;
  overflow-x: hidden;
}
#root {
  --primary-dark: #333; /* Adjust the color as needed */
  --primary-with-opacity: rgba(51, 51, 51, 0.5); /* Example color with opacity */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,WAAW;EACX;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,sCAAsC;EACtC,cAAc;EACd,oCAAoC;EACpC,eAAe;EACf,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,oBAAoB,EAAE,+BAA+B;EACrD,6CAA6C,EAAE,+BAA+B;AAChF;;AAEA;EACE;aACW;AACb","sourcesContent":["body {\n  margin: 0;\n  padding: 0;\n  width: 100%;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  font-family: 'Inter Tight', sans-serif;\n  color: #11181c;\n  font-family: Inter Tight, sans-serif;\n  font-size: 18px;\n  line-height: 1.1em;\n  overflow-x: hidden;\n}\n#root {\n  --primary-dark: #333; /* Adjust the color as needed */\n  --primary-with-opacity: rgba(51, 51, 51, 0.5); /* Example color with opacity */\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
