import logo from './logo.svg';
import './App.css';
import { HomePage } from './pages/homePage';

function App() {
  return (
    <HomePage/>
  );
}

export default App;
