import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import img from '../../assets/rektt.png';
import { Card } from 'reactstrap';
import { constants } from '../../data/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmileBeam } from '@fortawesome/free-solid-svg-icons'; 
 

const slideInFromLeft = keyframes`
  from { transform: translateX(-100%); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
`;

const slideInFromRight = keyframes`
  from { transform: translateX(100%); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
`;

const animationStyle = css`
  ${({ textOnLeft }) => textOnLeft ? css`animation: ${slideInFromRight} 1s forwards;` : css`animation: ${slideInFromLeft} 1s forwards;`}
`;

const CardContainer = styled.div`
  width: 100%;
  max-width: 1220px;
  display: flex;
  flex-direction: ${({ textOnLeft }) => textOnLeft ? 'row' : 'row-reverse'};
  justify-content: space-between;
  gap: 80px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
`;

const TextSection = styled.div`
  flex: 1;
  ${animationStyle};

  @media (max-width: 768px) {
    order: ${({ textOnLeft }) => textOnLeft ? 0 : 1};
    text-align: center;
  }
`;

const ImageSection = styled.div`
  flex: 1;
  ${animationStyle};

  @media (max-width: 768px) {
    order: ${({ textOnLeft }) => textOnLeft ? 1 : 0};
  }
`;


const IconSection = styled.div`
  flex: 1;
  ${animationStyle};
  font-size: 4rem; // Adjust the icon size as needed
  display: flex;
  justify-content: center; // Center the icon horizontally
  align-items: center; // Center the icon vertically

  @media (max-width: 768px) {
    order: ${({ textOnLeft }) => textOnLeft ? 1 : 0};
  }
`;
const Image = styled.img`
  width: 100%;
  height: auto;
`;

const StyledP = styled.p`
  padding-top: 140px;
  color: var(--paragraph-gray);
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.6em;

  @media (max-width: 768px) {
    padding-top: 20px;
  }
`;

const StyledCard = styled(Card)`
  background-color: ${constants.fourthColor};
  border-radius: 20px;
  flex-direction: row;
  display: flex;
  width: 90%;
  height: 550px; // Adjust height to be auto for flexibility
  align-self: center;
  margin-top: 60px;
  padding: 20px; // Add some padding for smaller screens

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    height: auto; // Make sure height adjusts to content
  }
`;

export const Projs = ({ textOnLeft, header, text, icon }) => {
  // Choose an icon based on the 'icon' prop or use a default
  // This is where you dynamically select the icon to be displayed
  // Example: Default to 'FaRegSmileBeam' if no icon is provided
  
  return (
    <StyledCard>
      <CardContainer textOnLeft={textOnLeft}>
        <TextSection textOnLeft={textOnLeft}>
          <h2>{header}</h2>
          <StyledP>{text}</StyledP>
        </TextSection>
        <IconSection textOnLeft={!textOnLeft}>
        <FontAwesomeIcon icon={icon} />
        </IconSection>
      </CardContainer>
    </StyledCard>
  );
};